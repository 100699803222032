<template>
  <div class="main">
    <agent-info-comp></agent-info-comp>

    <agent-sub-title>환전신청</agent-sub-title>

    <div class="gameinfo">
      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin-top: 5px">
        <div style="width: 34%;text-align: center">
          <h5 style="text-align: center">보유금액/은행/예금주</h5>
          <span style="color: #3a8ee6">{{ $store.state.agent.agentInfo.cash|comma }}</span>/
          <span style="color: #3a8ee6">{{ $store.state.agent.agentInfo.bank }}</span>/
          <span style="color: #3a8ee6">{{ $store.state.agent.agentInfo.beneficiary }}</span>
        </div>
      </div>
      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin-top: 5px">
        <div style="width: 34%">
          <h5 style="text-align: center">출금금액</h5>
          <el-input size="mini" v-model="applyCash.amount"></el-input>
        </div>
      </div>
      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin-top: 5px">
        <div style="width: 34%">
          <h5 style="text-align: center">출금비밀번호</h5>
          <el-input size="mini" v-model="applyCash.exchangePasswd"></el-input>
        </div>
      </div>
      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin-top: 20px">
        <el-button @click="exchangenow()">출금하기</el-button>
      </div>
    </div>

    <div style=" box-sizing: border-box; padding: 10px 0;">
      <div>
        <el-table
            :data="selfExchangeList"
            style="width: 100%"
            border>
          <el-table-column
              label="날짜"
              width="">
            <template slot-scope="scope">
              {{ scope.row.createTime|datef('MM-DD HH:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column
              label="금액"
              width="">
            <template slot-scope="scope">
              {{ scope.row.amount|comma }}
            </template>
          </el-table-column>


          <el-table-column
              label="상태"
              width="">
            <template slot-scope="scope">
              <span v-if="scope.row.status == agentConst.ApplyCash.STATUS_CHECKING">미확인</span>
              <span v-if="scope.row.status == agentConst.ApplyCash.STATUS_CHECKING">확인중</span>
              <span v-if="scope.row.status == agentConst.ApplyCash.STATUS_COMPLITE"
                    style="color: deepskyblue">완료</span>
              <span v-if="scope.row.status == agentConst.ApplyCash.STATUS_CANCEL"
                    style="color: red">실패</span>
              <el-button size="mini"
                  v-if="scope.row.status == agentConst.ApplyCash.STATUS_COMPLITE || scope.row.status == agentConst.ApplyCash.STATUS_CANCEL"
                  type="danger" @click.native="deleteExchangeHistory(scope.row.id)">삭제
              </el-button>
            </template>
          </el-table-column>

        </el-table>
      </div>
      <div style="margin-top: 20px">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotal">
        </el-pagination>
      </div>
    </div>
  </div>

</template>

<script>

import {Loading} from 'element-ui';
import AgentSubTitle from "../../components/agent/AgentSubTitle";
import {agentMixin} from "../../common/agent/agentMixin";
import {
  agentExchangeCash, deleteAgentExchange,
  doLogin,
  getAgentById,
  moveagentmoney,
  selfExchangeHistory
} from "../../network/agent/commonRequest";
import AgentInfoComp from "../../components/agent/AgentInfoComp";

export default {
  name: "AgentExchangeCash",
  components: {
    AgentInfoComp,
    AgentSubTitle,
  },
  mixins: [agentMixin],
  data() {
    return {
      applyCash: {},
      fullscreenLoading: false,
      selfExchangeList: [],
      pageNum: 1,
      pageSize: 10,
      pageTotal: 0,
    }
  },
  methods: {
    exchangenow() {
      this.fullscreenLoading = true;
      agentExchangeCash(this.applyCash).then(res => {
        this.fullscreenLoading = false;
        if (res.data.success) {
          this.$message.success("출금신청이 완료되였습니다");
          this.applyCash = {};
          getAgentById(-1).then(res => {
            this.$store.state.agent.agentInfo = res.data.data;
          })
          this.getSelfExchangeList();
        } else {
          this.$message.error(res.data.msg);
        }
      })

    },
    getSelfExchangeList() {
      selfExchangeHistory(this.pageNum, this.pageSize).then(res => {
        this.pageTotal = res.data.total
        this.selfExchangeList = res.data.data;
      })
    },
    deleteExchangeHistory(id){
      deleteAgentExchange(id).then(res=>{
        if (res.data.success) {
          this.getSelfExchangeList();
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.getSelfExchangeList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getSelfExchangeList();
    },
  },
  created() {
    this.getSelfExchangeList()
  }
}
</script>

<style scoped>
.table50 {
  border: 2px solid #0c0e0e;
  margin-top: 5px;
  font-weight: 600;
}

.data {
  width: 100%;
  height: calc(100vh - 185px);
  overflow-y: scroll;
  align-items: flex-start;
}

.gameinfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: #b3d8ff;
  box-sizing: border-box;
  padding: 10px;
}

.g1 {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #b3d8ff;
  padding: 5px;
  border-radius: 3px;
}

</style>